import styled, { css } from "styled-components"
import { IDetailContent, ITriangle } from "./types"

export const DetailWrapper = styled.div`
  position: relative;
  overflow: hidden;
`

export const ClickableArea = styled.div`
  display: flex;
  align-items: center;
  height: 42px;
  cursor: pointer;
  & :hover {
    & > div {
      background-color: ${props => props.theme.colors.orange};
    }
    & > p {
      color: ${props => props.theme.colors.orange};
    }
  }
`
export const Triangle = styled.div<ITriangle>`
  transition: 0.3s;
  clip-path: polygon(0 1%, 0% 100%, 100% 0);
  background-color: ${props => props.theme.colors.darkBlue};
  width: 12px;
  height: 12px;
  transform: ${props =>
    props.isActive ? css`rotateZ(225deg)` : css`rotateZ(135deg)`};
  margin-right: 10px;
`
export const DetailName = styled.p`
  transition: 0.3s;
  font-size: ${props => props.theme.fontSizes.normal};
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.colors.darkBlue};
`

export const DetailContent = styled.div<IDetailContent>`
  transition: 0.3s;
  opacity: ${props => (props.isActive ? 1 : 0)};
  position: relative;
  height: ${props => (props.isActive ? props.height + "px" : "0")};
`
export const ContentHeight = styled.p`
  position: relative;
`
