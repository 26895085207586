import React from "react"
import { JobWrapper, DetailsContainer } from "./style"
import { H3Title, OrangeSpan } from "../../../../globalStyle"
import Details from "../Details"
import IJob from "./types"

const Job = (props: IJob) => {
  const {
    title,
    benefits,
    methodology,
    details,
    description,
  } = props.jobData.node
  return (
    <JobWrapper>
      <H3Title isDarkBlue={true}>
        {title}
        <OrangeSpan></OrangeSpan>
      </H3Title>
      <DetailsContainer>
        <Details
          name="Brief Job Description"
          details={description.description}
        />
        <Details name="Interview" details={methodology.methodology} />
        <Details name="Offer Details" details={details.details} />
        <Details name="Benefits" details={benefits.benefits} />
      </DetailsContainer>
    </JobWrapper>
  )
}

export default Job
