import styled from "styled-components"

export const JobWrapper = styled.div`
  margin: 15px 15px 60px 15px;
  display: flex;
  flex-direction: column;
`

export const DetailsContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
`
