import { useStaticQuery, graphql } from "gatsby"

const useJobsQuery = () => {
  const jobs = useStaticQuery(graphql`
    query jobsQuery {
      allContentfulJob {
        edges {
          node {
            description {
              description
            }
            details {
              details
            }
            methodology {
              methodology
            }
            benefits {
              benefits
            }
            title
          }
        }
      }
    }
  `)
  return jobs
}
export default useJobsQuery
