import React, { useState, useEffect, useRef } from "react"
import { GrayBorder } from "../../../../globalStyle"
import {
  DetailWrapper,
  ClickableArea,
  Triangle,
  DetailName,
  DetailContent,
  ContentHeight,
} from "./style"
import IDetails from "./types"

const Details = (props: IDetails) => {
  const { name, details } = props
  const [detailActive, setDetailActive] = useState<boolean>(false)
  const [height, setHeight] = useState(0)
  const ref = useRef(null)
  const toggleDetails = () => {
    setDetailActive(!detailActive)
  }

  useEffect(() => {
    setHeight(ref.current.clientHeight ? ref.current.clientHeight : 0)
    function handleResize() {
      setHeight(ref.current.clientHeight ? ref.current.clientHeight : 0)
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <DetailWrapper>
      <ClickableArea onClick={toggleDetails}>
        <Triangle isActive={detailActive} />
        <DetailName>{name}</DetailName>
      </ClickableArea>
      <GrayBorder />
      <DetailContent isActive={detailActive} height={height}>
        <ContentHeight
          dangerouslySetInnerHTML={{ __html: details }}
          ref={ref}
        ></ContentHeight>
      </DetailContent>
    </DetailWrapper>
  )
}

export default Details
